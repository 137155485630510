.hunarmand-buttons-container {
  display: flex;
  gap: 16px;
  padding: 20px;
  justify-content: center;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 16px;
    gap: 12px;
  }

  .hunarmand-button-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
    }
  }

  .hunarmand-button-description {
    font-size: 14px;
  }

  .hunarmand-action-button {
    justify-content: center;
    min-width: 70px;
    // height: 25px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 2px 2px;
    // border-radius: 8px !important;
    transition: all 0.3s ease !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 768px) {
      height: 24px !important;
      font-size: 12px !important;
      padding: 4px 4px !important;
    }

    .anticon {
      font-size: 18px;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    &.vendor-button {
      background-color: #d84315 !important;
      border-color: #d84315 !important;

      &:hover {
        background-color: #d84315 !important;
        border-color: #d84315 !important;
        transform: translateY(-2px);
      }
    }

    &.product-button {
      background-color: #d84315 !important;
      border-color: #d84315 !important;

      &:hover {
        background-color: #bf360c !important;
        border-color: #bf360c !important;
        transform: translateY(-2px);
      }
    }
  }
}

// Additional mobile styles
.hunarmand-buttons-container.mobile {
  padding: 12px;
  margin-bottom: 16px;

  display: flex;
  gap: 16px;
  padding: 20px;
  justify-content: center;
}
