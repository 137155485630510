.hunarmand-section-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 32px;
  background-color: #ffffff;
}

.hunarmand-desktop-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 35px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.hunarmand-text-section {
  width: 34%;
  padding: 30px 20px;

  .hunarmand-view-more-container {
    display: flex;
    justify-content: flex-start;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.hunarmand-section-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.3;
  color: #333;
  margin-bottom: 16px;
  margin-top: 0;
  text-decoration: underline;
  text-transform: uppercase;
}

.hunarmand-description {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 24px;
}

.hunarmand-button {
  height: auto !important;
  padding: 2px 5px !important;

  color: #d84315 !important;
  text-decoration: underline;
  font-size: 14px !important;
  font-weight: 700 !important;

  // background-color: #d84315 !important;
  // border-color: #d84315 !important;

  a {
    color: #d84315 !important;
    font-size: 14px;
    font-weight: bold !important;
  }
}

.hunarmand-carousel {
  .slick-arrow {
    color: #000;
  }
}

.hunarmand-carousel-section {
  width: 65%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 20px 20px 0 25px;
  // background-color: #f5f5f5;

  @media (max-width: 768px) {
    width: 100%;
  }

  :global(.ant-carousel) {
    margin: 0 -10px;
  }

  :global(.slick-track) {
    display: flex;
    gap: 20px;
  }

  :global(.slick-slide) {
    > div {
      padding: 0;
    }
  }
}

.hunarmand-item {
  width: 90% !important;
  background-color: #ffffff;
}

.hunarmand-image-container {
  margin-bottom: 12px;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 1;
}

.hunarmand-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.hunarmand-label {
  font-size: 14px;
  color: #333;
  margin-top: 8px;
  text-align: center;
}

.hunarmand-arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  z-index: 1 !important;
  width: 30px !important;
  height: 26px !important;
  // background-color: white !important;
  background-color: none !important;
  border: none !important;
  border-color: #e8e8e8 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;

  &:hover {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }

  .anticon {
    color: #000;
    font-size: 12px;
    font-weight: 600;
  }

  &-prev {
    left: -20px !important;
    display: none;
  }

  &-next {
    right: -10px !important;
  }
}

// Mobile Styles
.hunarmand-container-mobile {
  margin: 0 auto;
  padding: 2px 16px;

  .hunarmand-button-mobile {
    height: auto !important;
    padding: 8px 16px !important;
    font-size: 14px !important;
    border: none !important;

    a {
      color: #d84315 !important;
      text-decoration: underline;
    }

    &:hover {
      background-color: darken(#d84315, 10%) !important;
      border-color: darken(#d84315, 10%) !important;
    }
  }

  .hunarmand-mobile-wrapper {
    display: flex;
    flex-direction: column;

    .hunarmand-view-more-container-mobile {
      display: flex;
      justify-content: center;
    }
  }

  .hunarmand-text-section-mobile {
    text-align: left;
    padding: 0;

    .hunarmand-view-more-container-mobile {
      display: flex;
      justify-content: center;
    }

    .hunarmand-title-mobile {
      font-size: 22px;
      font-weight: 700;
      color: #333;
      margin-bottom: 12px;
      text-decoration: underline;
      text-transform: uppercase;
    }

    .hunarmand-description-mobile {
      font-size: 14px;
      line-height: 1.5;
      color: #666;
      margin-bottom: 16px;
    }
  }

  .hunarmand-carousel-section-mobile {
    padding: 0px 8px;

    :global(.ant-carousel) {
      margin: 0 -8px;
    }

    :global(.slick-track) {
      display: flex;
      gap: 16px;
    }

    :global(.slick-slide) {
      > div {
        padding: 0;
      }
    }
  }

  .hunarmand-item-mobile {
    width: 100% !important;
    background-color: #ffffff;
    padding: 8px;
  }

  .hunarmand-image-container-mobile {
    margin-bottom: 8px;
    border-radius: 8px;
    overflow: hidden;
    aspect-ratio: 1;
  }

  .hunarmand-image-mobile {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .hunarmand-label-mobile {
    font-size: 13px;
    color: #333;
    margin-top: 6px;
    text-align: center;
  }
}
