.countdown-timer {
  position: absolute;
  top: 17%;
  right: 7%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Arial", sans-serif;
  perspective: 1000px;
  z-index: 1;
}

.time-unit {
  margin: 0 5px;
  text-align: center;
  width: 50px;
}

.number {
  padding: 12px 0px;
  height: 50px;
  width: 100%;
  background: linear-gradient(145deg, #f0f0f0, #f0f0f0);
  color: red;

  border-radius: 8px;
  border: 2px solid black;
  display: inline-block;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  font-size: 1.5rem;
  font-weight: 700;

  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2),
    -3px -3px 10px rgba(255, 255, 255, 0.8),
    inset 2px 2px 4px rgba(0, 0, 0, 0.1),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5);
}

.number:hover {
  transform: scale(1.05);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3),
    -5px -5px 15px rgba(255, 255, 255, 0.8),
    inset 2px 2px 8px rgba(0, 0, 0, 0.2),
    inset -2px -2px 8px rgba(255, 255, 255, 0.6);
}

.label {
  font-size: 0.8rem;
  margin-top: 2px;
  color: white;
  font-weight: 700;
}

.number.flip {
  animation: flip 0.6s forwards;
  box-shadow: 0 0 0 #ffffff;
}

@keyframes flip {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@media (min-width: 1900px) {
  .countdown-timer {
    position: absolute;
    top: 16%;
    right: 7%;
  }
  .time-unit {
    margin: 0 5px;
    width: 120px;
  }
  .number {
    padding: 30px 0px;
    height: 100px;
    font-size: 2.7rem;
  }
  .label {
    font-size: 1.7rem;
    margin-top: 2px;
    color: white;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .countdown-timer {
    top: 15%;
    /* position: fixed; */
    right: 5px;
    justify-content: flex-end;
  }

  .time-unit {
    margin: 0 3px;
    text-align: center;
    width: 25px;
  }

  .number {
    padding: 7px 0px;
    height: 27px;
    width: 100%;
    background: linear-gradient(145deg, #f0f0f0, #f0f0f0);
    color: red;
    border-radius: 8px;
    border: 2px solid black;
    display: inline-block;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    font-size: 0.8rem;
    font-weight: 700;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2),
      -3px -3px 10px rgba(255, 255, 255, 0.8),
      inset 2px 2px 4px rgba(0, 0, 0, 0.1),
      inset -2px -2px 4px rgba(255, 255, 255, 0.5);
  }

  .label {
    font-size: 0.5rem;
    margin-top: 2px;
    color: white;
    font-weight: 700;
  }
}
